.btn {
    display: inline-block;
    padding: 0 33px;
    background-color: $green;
    border: 2px solid $green;
    border-radius: 20px;
    transition: 0.3s ease;
    &.br-30 {border-radius: 30px;}
    &:hover {
        background-color: $darkgreen;
        border: 2px solid $darkgreen;
    }
    & span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 42px;
        font-family: 'Montserrat SemiBold';
        font-size: 13px;
        line-height: 13px;
        text-transform: uppercase;
        color: $white;
        transition: 0.3s ease;
    }
    &-lightgreen {
        background-color: $darkgreen;
        border: 2px solid $darkgreen;
    }
    &-m {
        & span {
            min-height: 50px;
            font-size: 18px;
            line-height: 18px;
            text-transform: inherit;
        }
    }
    &-border {
        background-color: transparent;
        & span {color: $green;}
        &:hover {
            background-color: $darkgreen;
            border: 2px solid $darkgreen;
            & span {color: $white;}
        }
    }
    &-blue {
        background-color: #30449A;
        border: 2px solid #30449A;
        padding: 0 15px;
        & span {
            font-size: 14px;
            line-height: 14px;
            text-transform: inherit;
        }
        &:hover {
            background-color: #0c238a;
            border: 2px solid #0c238a;
        }
    }
    &-check {
        border-radius: 0;
        &-disabled {
            background: $textgray4 !important;
            border: 2px solid $textgray4 !important;
        }
        & span {
            min-height: 70px;
            font-family: 'Montserrat Medium';
            font-size: 12px;
            line-height: 12px;
            text-transform: inherit;
        }
    }
}