.about {
    padding-bottom: 205px;
    @media (max-width: 992px) {
        padding-bottom: 75px;
    }
    &-cnt {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start; 
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }
    &-img {
        width: 100%;
        max-width: 600px;
        margin-bottom: 30px;
        margin-right: 80px;
        border-radius: 10px;
        overflow: hidden;
        @media (max-width: 1200px) {
            max-width: 400px;
            margin-right: 30px;
        }
        @media (max-width: 767px) {
            max-width: 100%;
            margin-right: 0;
        }
        & img {
            max-width: 100%;
            width: 100%;
        }
    }
    &-info {
        width: 100%;
        @media (max-width: 767px) {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
}

.info-line {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 40px;
    @media (max-width: 767px) {
        width: 48%;
    }
    @media (max-width: 550px) {
        width: 100%;
    }
    &-center {
        align-items: center;
        margin-bottom: 50px;
    }
    &-big {
        width: 100%;
        max-width: 393px;
        @media (max-width: 992px) {
            width: 48%;
            max-width: 100%;
        }
        @media (max-width: 600px) {
            width: 100%;
            max-width: 100%;
        }
    }
    &-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        min-width: 52px;
        max-width: 52px;
        margin-right: 20px;
        &-big {
            width: 76px;
            min-width: 76px;
            max-width: 76px;
            margin-right: 9px;
            @media (max-width: 600px) {
                width: 52px;
                min-width: 52px;
                max-width: 52px;
                margin-right: 15px;
            }
        }
        & img {max-width: 100%;}
    }
    &-cnt {
        width: 100%;
        max-width: 317px;
        padding-right: 15px;
        font-family: 'Montserrat Regular';
        font-size: 15px;
        line-height: 24px;
        color: $textgray7;
        @media (max-width: 550px) {
            max-width: 100%;
            font-size: 13px;
            line-height: 20px;
        }
    }
}