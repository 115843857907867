.banner {
    padding-bottom: 75px;
    @media (max-width: 1200px) {
        padding-bottom: 34px;
    }
    &-list {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-flow: row wrap;
        width: 100%;
        @media (max-width: 767px) {
            justify-content: space-around;
            width: calc(100% + 30px);
            margin-left: -15px;
        }
        &-center {
            display: flex;
            align-items: stretch;
            justify-content: center;
            flex-flow: row wrap;
            width: 100%;
            @media (max-width: 767px) {
                justify-content: space-around;
            }
            @media (max-width: 530px) {
                justify-content: space-around;
            }
            @media (max-width: 450px) {
                justify-content: space-around;
            }
        }
    }
    &-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        overflow: hidden;
        margin-bottom: 23px;
        transition: 0.3s ease;
        &-slider {
            width: 100%;
        }
        @media (max-width: 767px) {
            margin-bottom:15px;
        }
        & img {
            max-width: 100%;
            max-height: 100%;
            transition: 0.5s linear;
        }
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            transition: 0.3s ease;
            opacity: 0;
        }
        &:hover{
            &::before {opacity: 1;}
            & img {
                transform: scale(1.2);
            }
        }
    }
    &-medium {
        max-width: 24%;
        max-height: 317px;
        border-radius: 20px;
        @media (max-width: 530px) {
            max-width: 156px;
            max-height: 148px;
        }
        & span {
            position: absolute;
            bottom: 39px;
            left: 0;
            display: block;
            width: 100%;
            padding: 10px 10px 0;
            z-index: 2;
            font-family: 'Century Gothic Bold';
            font-size: 28px;
            line-height: 32px;
            text-align: center;
            color: $white;
            @media (max-width: 767px) {
                font-size: 21px;
                line-height: 24px;
            }
        }
    }
    &-small {
        max-width: 161px;
        max-height: 98px;
        border-radius: 20px;
        @media (max-width: 530px) {
            max-width: 160px;
            max-height: 94px;;
        }
        &:not(:last-child) {
            margin-right: 24px;
            @media (max-width: 1400px) {
                margin-right: 5px;
            }
            @media (max-width: 530px) {
                margin-right: 0px;
            }
        }
        & span {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 10px 10px;
            z-index: 2;
            font-family: 'Century Gothic Bold';
            font-size: 23px;
            line-height: 26px;
            text-align: center;
            color: $white;
        }
    }
}