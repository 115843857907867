.cabinet {
    padding-bottom: 65px;
    & .title {
        margin-bottom: 12px;
    }
    &-links {
        display: inline-block;
        margin-bottom: 38px;
        &-layout{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-flow: row wrap;
            padding: 14px 25px;
            border: 1px solid $bordergray4;
            border-radius: 20px;
        }
        & a {
            display: inline-block;
            font-family: 'Century Gothic Bold';
            font-size: 11px;
            line-height: 11px;
            color: $textlightgray2;
            transition: 0.3s ease;
            & span {
                text-transform: uppercase;
            }
            @media (max-width: 600px) {
                margin: 5px 0;
            }
            &:not(:last-child) {margin-right: 17px;}
            &:hover,
            &.active {color: $green;}
        }
    }
    &-info {
        &-list {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            flex-flow: row wrap;
            @media (max-width: 767px) {
                flex-direction: column;
                align-items: center;
            }
        }
        &-item {
            position: relative;
            width: 100%;
            min-height: 200px;
            max-width: 386px;
            padding: 25px 18px 50px 30px;
            background-color: $bordergray6;
            border-radius: 13px;
            margin-bottom: 30px;
            border: 1px solid $bordergray4;
            transition: 0.3s ease;
            @media (min-width: 768px) {
                order: 1;
            }
            @media (max-width: 1770px) {
                max-width: 320px;
            }
            @media (max-width: 767px) {
                max-width: 100%;
                margin: 0 0 30px !important;
            }
            &:not(:last-child) {
                margin-right: 40px;
                @media (max-width: 1770px) {
                    margin-right: 15px;
                }
            }
            &:hover,
            &.active {
                border: 1px solid $green; 
            }
            &-add {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 386px;
                min-height: 200px;
                padding: 25px;
                background-color: transparent;
                border-radius: 13px;
                margin-bottom: 30px;
                border: 1px solid $bordergray4;
                transition: 0.3s ease;
                @media (min-width: 768px) {
                    order: 2;
                }
                @media (max-width: 1770px) {
                    max-width: 320px;
                }
                @media (max-width: 767px) {
                    max-width: 100%;
                    margin: 0 0 30px !important;
                }
                &:hover,
                &.active {
                    border: 1px solid $green; 
                }
                & a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    & img {
                        margin-right: 10px;
                        width: 30px;
                        min-width: 30px;
                        max-width: 30px;
                    }
                    & span {
                        font-family: 'Montserrat SemiBold';
                        font-size: 16px;
                        line-height: 16px;
                        color: $textgray7;
                        transition: 0.3s ease;
                    }
                }
                &:hover span {color: $green;}
            }
            & .log-out {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 40px;
                min-width: 40px;
                max-width: 40px;
                border-radius: 50%;
                background-color: $green;
                transition: 0.3s ease;
                &:hover {
                    background-color: $darkgreen;
                }
                & img {
                    width: 16px;
                    min-width: 16px;
                    max-width: 16px;
                }
            }
            &-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 9px;
                font-family: 'Montserrat SemiBold';
                font-size: 24px;
                line-height: 32px;
                color: $textgray7;
            }
            &-text {
                display: block;
                margin-bottom: 13px;
                font-family: 'Montserrat Regular';
                font-size: 15px;
                line-height: 24px;
                color: $textgray7;
                &:not(:last-child) {margin-bottom: 10px;}
            }
            &-bottom {
                position: absolute;
                bottom: 31px;
                left: 0;
                width: 100%;
                padding: 0 18px 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                & a {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    & img {
                        margin-right: 10px;
                        width: 16px;
                        min-width: 16px;
                        max-width: 16px;
                    }
                    & span {
                        font-family: 'Montserrat Regular';
                        font-size: 15px;
                        line-height: 15px;
                        color: $textgray7;
                        transition: 0.3s ease;
                    }
                    &:hover span {color: $green;}
                }
            }
        }
    }
    &-history {
        &-list {
            width: 100%;
            max-width: 813px;
        }
        &-item {
            margin-bottom: 20px;
            &-head {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px 10px 30px;
                min-height: 60px;
                background-color: #F8F8F8;
                transition: 0.3s ease;
                border-radius: 10px;
                @media (max-width: 767px) {
                    padding: 10px 15px;
                }
                &-left {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    & img {
                        max-width: 8px;
                        min-width: 8px;
                        margin-right: 10px;
                        transform: rotate(180deg);
                        transition: 0.3s ease;
                    }
                    & p {
                        font-family: 'Montserrat Regular';
                        font-size: 15px;
                        line-height: 15px;
                        color: $textgray7;
                    }
                }
                &-right {
                    font-family: 'Montserrat Regular';
                    font-size: 15px;
                    line-height: 15px;
                    color: $textgray7;
                }
            }
            &-content {
                display: none;
                padding: 18px 0;
            }
            &-el {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 20px;
                &-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100px;
                    max-width: 100px;
                    min-width: 100px;
                    height: 100px;
                    margin-right: 20px;
                    & img {
                        max-width: 100%;
                        max-height: 100px;
                    }
                }
                &-cnt {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    @media (max-width: 767px) {
                        flex-flow: row wrap;
                        justify-content: flex-start;
                    }
                    &-left {
                        width: 100%;
                        max-width: 420px;
                        @media (max-width: 767px) {
                            max-width: 100%;
                        }
                        & a {
                            margin-bottom: 5px;
                            display: block;
                            width: 100%;
                            font-family: 'Century Gothic Regular';
                            font-size: 18px;
                            line-height: 24px;
                            color: $textgray7;
                            transition: 0.3s ease;
                            &:hover {color: $green;}
                        }
                        & p {
                            display: block;
                            font-family: 'Montserrat Regular';
                            font-size: 15px;
                            line-height: 24px;
                            color: $textlightgray;
                        }
                    }
                    & > span {
                        display: block;
                        width: 100%;
                        max-width: 135px;
                        min-width: 135px;
                        display: block;
                        font-family: 'Montserrat SemiBold';
                        font-size: 24px;
                        line-height: 24px;
                        text-align: center;
                        color: $textlightgray;
                        @media (max-width: 767px) {
                            width: 100%;
                            margin-top: 10px;
                            max-width: 50px;
                            min-width: 0;
                            font-size: 14px;
                            line-height: 14px;
                            text-align: left;
                        }
                    }
                    & > p {
                        display: block;
                        width: 100%;
                        max-width: 135px;
                        min-width: 135px;
                        display: block;
                        font-family: 'Montserrat SemiBold';
                        font-size: 24px;
                        line-height: 24px;
                        color: $textgray7;
                        text-align: right;
                        @media (max-width: 767px) {
                            margin-top: 10px;
                            width: 100%;
                            max-width: 110px;
                            min-width: 0;
                            font-size: 14px;
                            line-height: 14px;
                            text-align: left;
                            padding-left: 5px;
                        }
                    }
                }
            }
            &-bottom {
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                margin-bottom: 30px;
                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                &-col {
                    width: 100%;
                    max-width: 250px;
                    @media (max-width: 767px) {
                        max-width: 100%;
                        &:not(:last-child) {
                            margin: 0 0 26px !important;
                        }
                    }
                    & p {
                        display: block;
                        margin-bottom: 10px;
                        font-family: 'Montserrat SemiBold';
                        font-size: 18px;
                        line-height: 18px;
                        color: $textgray7;
                    }
                    
                    & span {
                        display: block;
                        font-family: 'Montserrat Regular';
                        font-size: 15px;
                        line-height: 24px;
                        color: $textgray7;
                    }
                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                    &.price-col {
                        padding: 10px 10px 10px 24px;
                        background-color: #F8F8F8;
                        border-radius: 10px;
                        @media (max-width: 767px) {
                            max-width: 300px;
                        }
                        & p {
                            display: block;
                            margin-bottom: 10px;
                            font-family: 'Montserrat Regular';
                            font-size: 15px;
                            line-height: 15px;
                            color: $textgray7;
                            & span {
                                display: inline;
                                font-family: 'Montserrat SemiBold';
                                font-size: 36px;
                                line-height: 36px;
                                & span {
                                    display: inline;
                                    font-size: 21px;
                                    line-height: 21px;
                                }
                            }
                        }
                    }
                }
            }
            &-btn {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @media (max-width: 767px) {
                    justify-content: flex-start;
                }
            }
            &.active {
                & .cabinet-history-item-head {
                    background-color: rgba(118, 187, 185, 0.20);
                    &-left {
                        transform: rotate(0);
                    }
                }
            }
            &:hover {
                & .cabinet-history-item-head {
                    background-color: rgba(118, 187, 185, 0.20);
                } 
            }
        }
    }
}

.hidden-element-wrap {
    width: 100%;
    @media (min-width: 768px) {
        order: 5;
    }
    & .hidden-element {
        display: none;
        @media (max-width: 767px) {
            margin: 0 0 30px;
        }
        &.active {
            display: block;
        }
    }
}

.change-profile {
    width: 100%;
    & form {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%;
        max-width: 660px;
        @media (max-width: 767px) {
            max-width: 100%;
        }
    }
    &-item {
        width: calc(50% - 10px);
        max-width: 320px;
        margin-bottom: 20px;
        @media (max-width: 767px) {
            width: 100%;
            max-width: 100%;
        }
        &-s {
            width: 100%;
            max-width: 150px;
            @media (max-width: 359px) {
                max-width: 130px;
            }
            &:not(:last-child) {
                margin-right: 20px;
                @media (max-width: 767px) {
                    margin-right: 0;
                }
            }
        }
    }
    & p {
        font-family: 'Montserrat Medium';
        font-size: 12px;
        line-height: 18px;
        color: $textgray7;
    }
    &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        & .btn {
            padding: 0 15px;
            border-radius: 30px;
            & span {
                min-height: 44px;
                font-family: 'Montserrat Regular';
                font-size: 15px;
                line-height: 15px;
                text-transform: initial;
            }
            &-big {
                padding: 0 33px;
                & span {
                    font-family: 'Montserrat SemiBold';
                    font-size: 18px;
                    line-height: 18px;
                }
            }
        }
    }
}

.change-address {
    width: 100%;
    & form {
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        justify-content: flex-start;
        width: 100%;
        max-width: 690px;
        @media (max-width: 767px) {
            max-width: 100%;
            justify-content: space-between;
        }
        & .change-profile-item {
            &:not(:last-child) {
                margin-right: 20px;
                @media (max-width: 767px) {
                    margin-right: 0;
                }
            }
        }
        & .change-profile-row {
            padding-right: 30px;
            @media (max-width: 767px) {
                padding-right: 0;
            }
        }
    }
}



