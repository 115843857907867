.delivery {
    padding-bottom: 205px;
    @media (max-width: 992px) {
        padding-bottom: 75px;
    }
    &-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-flow: row wrap;
    }
}