$white: #ffffff;
$bordergray: #E8E8E8;
$bordergray2: #E6E6E6;
$bordergray3: #EEEEEE;
$bordergray4: #ECECEC;
$bordergray5: #F6F6F6;
$bordergray6: #F8F8F8;
$textgray: #474747;
$textgray2: #2D2C31;
$textgray3: #A1A1A1;
$textgray4: #27262B;
$textgray5: #3F3F3F;
$textgray6:#6E6E6E;
$textgray7:#2B2C34;
$textgray8:#707070;
$textgray9:#8C8C8C;
$textlightgray: #B4B4B4;
$textlightgray2: #BABABA;
$textlightgray3: #C0C0C0;
$green: #76BBB9;
$darkgreen: #007F85;
