// Montserrat

@font-face {
    font-family: 'Montserrat Regular';
    src: url('./fonts/montserrat/Montserrat-Regular.eot');
    src: url('./fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
        url('./fonts/montserrat/Montserrat-Regular.woff') format('woff'),
        url('./fonts/montserrat/Montserrat-Regular.ttf') format('truetype'),
        url('./fonts/montserrat/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Medium';
    src: url('./fonts/montserrat/Montserrat-Medium.eot');
    src: url('./fonts/montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('./fonts/montserrat/Montserrat-Medium.woff2') format('woff2'),
        url('./fonts/montserrat/Montserrat-Medium.woff') format('woff'),
        url('./fonts/montserrat/Montserrat-Medium.ttf') format('truetype'),
        url('./fonts/montserrat/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Italic';
    src: url('./fonts/montserrat/Montserrat-Italic.eot');
    src: url('./fonts/montserrat/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
        url('./fonts/montserrat/Montserrat-Italic.woff2') format('woff2'),
        url('./fonts/montserrat/Montserrat-Italic.woff') format('woff'),
        url('./fonts/montserrat/Montserrat-Italic.ttf') format('truetype'),
        url('./fonts/montserrat/Montserrat-Italic.svg#Montserrat-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Bold';
    src: url('./fonts/montserrat/Montserrat-Bold.eot');
    src: url('./fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
        url('./fonts/montserrat/Montserrat-Bold.woff') format('woff'),
        url('./fonts/montserrat/Montserrat-Bold.ttf') format('truetype'),
        url('./fonts/montserrat/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat SemiBold';
    src: url('./fonts/montserrat/Montserrat-SemiBold.eot');
    src: url('./fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'),
        url('./fonts/montserrat/Montserrat-SemiBold.woff') format('woff'),
        url('./fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype'),
        url('./fonts/montserrat/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat Light';
    src: url('./fonts/montserrat/Montserrat-Light.eot');
    src: url('./fonts/montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/montserrat/Montserrat-Light.woff2') format('woff2'),
        url('./fonts/montserrat/Montserrat-Light.woff') format('woff'),
        url('./fonts/montserrat/Montserrat-Light.ttf') format('truetype'),
        url('./fonts/montserrat/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat ExtraBold';
    src: url('./fonts/montserrat/Montserrat-ExtraBold.eot');
    src: url('./fonts/montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/montserrat/Montserrat-ExtraBold.woff2') format('woff2'),
        url('./fonts/montserrat/Montserrat-ExtraBold.woff') format('woff'),
        url('./fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype'),
        url('./fonts/montserrat/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}



// bague 


@font-face {
    font-family: 'PF Bague Sans Pro Bold';
    src: url('./fonts/bague/PFBagueSansPro-Bold.eot');
    src: url('./fonts/bague/PFBagueSansPro-Bold.eot?#iefix') format('embedded-opentype'),
         url('./fonts/bague/PFBagueSansPro-Bold.woff2') format('woff2'),
         url('./fonts/bague/PFBagueSansPro-Bold.woff') format('woff'),
         url('./fonts/bague/PFBagueSansPro-Bold.ttf') format('truetype'),
         url('./fonts/bague/PFBagueSansPro-Bold.svg#PFBagueSansPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


// Century 


@font-face {
    font-family: 'Century Gothic Regular';
    src: url('./fonts/century/CenturyGothic.eot');
    src: url('./fonts/century/CenturyGothic.eot?#iefix') format('embedded-opentype'),
         url('./fonts/century/CenturyGothic.woff2') format('woff2'),
         url('./fonts/century/CenturyGothic.woff') format('woff'),
         url('./fonts/century/CenturyGothic.ttf') format('truetype'),
         url('./fonts/century/CenturyGothic.svg#CenturyGothic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic Bold';
    src: url('./fonts/century/CenturyGothic-Bold.eot');
    src: url('./fonts/century/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
         url('./fonts/century/CenturyGothic-Bold.woff2') format('woff2'),
         url('./fonts/century/CenturyGothic-Bold.woff') format('woff'),
         url('./fonts/century/CenturyGothic-Bold.ttf') format('truetype'),
         url('./fonts/century/CenturyGothic-Bold.svg#CenturyGothic-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

