.footer {
    padding: 32px 0 43px;
    background-color: $textgray5;
    @media (max-width: 1200px) {
        padding: 45px 0 43px;
    }
    &-top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 37px;
        @media (max-width: 1750px) {
            flex-flow: row wrap;
            margin-bottom: 18px;
        }
        & .logo {
            display: block;
            width: 100%;
            max-width: 203px;
            margin-right: 5px;
            @media (max-width: 1750px) {
                max-width: 150px;
                order: 1;
            }
            @media (max-width: 550px) {
                display: flex;
                align-items: center;
                justify-content: center;
                order: 6;
                max-width: 100%;
                margin: 50px auto 0 ;
                & img {
                    width: 100%;
                    max-width: 203px !important;
                }
            }
            & img {max-width: 100%;}
        }
    }
    &-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 550px) {
            flex-direction: column;
            align-items: center;
        }
        & p {
            font-family: 'Montserrat Regular';
            font-size: 10px;
            line-height: 10px;
            color: $textgray6;
            @media (max-width: 550px) {
                &:not(:last-child) {margin-bottom: 15px;}
            }
            & a {
                font-family: 'Montserrat Regular';
                font-size: 10px;
                line-height: 10px;
                color: $textgray6;
                transition: 0.3s ease;
                &:hover {color: $white;}
                & img {
                    display: inline-block;
                    margin-left: 8px;
                }
            }
        }
    }
    &-nav {
        @media (max-width: 1750px) {
            order: 5;
            width: 100%;
            margin-top: 30px;
        }
        @media (max-width: 550px) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            order: 1;
            margin: 0 0 34px;
        }
        &-top {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 15px;
            @media (max-width: 1750px) {
                justify-content: center;
            }
            @media (max-width: 550px) {
                flex-direction: column;
                align-items: flex-start;
            }
            & a {
                display: block;
                font-family: 'Century Gothic Regular';
                font-size: 18px;
                line-height: 18px;
                color: $white;
                transition: 0.3s ease;
                @media (max-width: 1750px) {
                    font-size: 16px;
                    line-height: 16px;
                }
                @media (max-width: 550px) {
                    font-size: 18px;
                    line-height: 18px;
                }
                &:hover {color: $green;}
                &:not(:last-child) {
                    margin-right: 26px;
                    @media (max-width: 1750px) {
                        margin-right: 10px;
                    }
                    @media (max-width: 550px) {
                        margin: 0 0 24px;
                    }
                }
            }
        }
        &-bottom {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @media (max-width: 1750px) {
                justify-content: center;
            }
            @media (max-width: 550px) {
                flex-direction: column;
                align-items: flex-start;
            }
            & a {
                display: block;
                font-family: 'Century Gothic Regular';
                font-size: 14px;
                line-height: 14px;
                color: $textlightgray;
                transition: 0.3s ease;
                @media (max-width: 1750px) {
                    font-size: 13px;
                    line-height: 13px;
                }
                @media (max-width: 550px) {
                    font-size: 14px;
                    line-height: 14px;
                }
                &:hover {color: $green;}
                &:not(:last-child) {
                    margin-right: 25px;
                    @media (max-width: 1750px) {
                        margin-right: 10px;
                    }
                    @media (max-width: 550px) {
                        margin: 0 0 28px;
                    }
                }
            }
        }
    }
    & .info-link {
        @media (max-width: 1750px) {
            order: 2;
        }
        & .info-link-cnt-title {
            color: $white;
            & span,
            & span span {
                color: $white;
            }
        }
        &-tel {
            @media (max-width: 1750px) {
                order: 3;
            }
            @media (max-width: 550px) {
                order: 5;
            }
            @media (max-width: 446px) {
                margin-top: 40px;
            }
            align-items: flex-start;
            & span,
            & span span {
                font-family: 'Montserrat Regular';
                font-size: 16px;
                line-height: 16px;
                color: $white;
            }
            & .info-link-img {margin-right: 8px;}
            & .info-link-cnt {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                & a {
                    transition: 0.3s ease;
                    &:not(:last-child) {margin-bottom: 8px;}
                    &:hover {
                        color: $green;
                        & span {color: $green;}
                    }
                }
            }
        }
    }
    & .social {
        @media (max-width: 1750px) {
            order: 4;
        }
        @media (max-width: 550px) {
            order: 3;
            margin: 0 20px;
        }
    }
}

.social {
    display: flex;
    align-items: center;
    justify-content: center;
    & a {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 23px;
        min-width: 23px;
        height: 23px;
        transition: 0.3s ease;
        &:not(:last-child) {margin-right: 11px;}
        &:hover {opacity: 0.7;}
        & img {max-width: 100%;}
    }
}