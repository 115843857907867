.sidebar {
    &-right {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 6;
        width: 100%;
        max-width: 360px;
        min-width: 360px;
        height: 100vh;
        padding: 110px 0 0 0;
        background-color: $white;
        border-left: 1px solid $bordergray;
        @media (max-width: 1200px) {
            display: none;
            max-width: 320px;
            min-width: 320px;
            padding-top: 130px;
            height: 100%;
            &.active {display: block;}
        }
        &-cnt {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
        }
    }
    &-left {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 6;
        width: 100%;
        max-width: 116px;
        min-width: 116px;
        height: 100vh;
        padding: 110px 0 30px 0;
        background-color: $white;
        // border-right: 1px solid $bordergray;
        @media (max-width: 1200px) {
            display: none;
        }
        &-cnt {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            // background-image: url('../images/left-s-bg.png');
            // background-repeat: no-repeat;
            // background-position:top 10px right -17px;
            // background-size: 78px 74px;
            padding: 0 23px 0 19px;
            &::-webkit-scrollbar {width: 2px;}
            &::-webkit-scrollbar-track {background: transparent;}
            &::-webkit-scrollbar-thumb {background: $green;}
            &::-webkit-scrollbar-thumb:hover {background: transparent;}
            &-img {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 1px;
                & svg {
                    max-width: 100%;
                    & path,
                    & circle {
                        transition: 0.3s ease;
                        fill: $textgray2;
                    }
                }
            }
            & a {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                & span {
                    display: block;
                    width: 100%;
                    font-family: 'Century Gothic Regular';
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    color: $textgray2;
                    transition: 0.3s ease;
                }
                &:not(:last-child) {margin-bottom: 43px;}
                &:hover {
                    & .sidebar-left-cnt-img svg path,
                    & .sidebar-left-cnt-img svg circle{fill: $green;}
                    & span {color: $green;}
                }
            }
        }
    }
}

.checkout {
    display: block;
    width: 100%;
    height: 100%;
    & form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        @media (max-width: 1200px)  {
            padding-bottom: 130px;
        }
        @media (max-height: 500px)  {
            padding-bottom: 50px;
        }
    }
    &-form {
        display: none;
        width: 100%;
        height: 100%;
        &-wrap {
            position: relative;
            padding: 20px 30px 40px;
            @media (max-width: 1200px) {
                padding: 40px 15px 40px;
            }
            &:not(:last-child) {
                border-bottom: 1px solid $bordergray5;
            }
        }
        &-title {
            margin-bottom: 28px;
            font-family: 'Century Gothic Bold';
            font-size: 16px;
            line-height: 16px;
            color: $textgray2;
        }
        &-title2 {
            margin-bottom: 24px;
            font-family: 'Montserrat SemiBold';
            font-size: 18px;
            line-height: 18px;
            color: $textgray2;
        }
        &-item {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 25px;
            &s {width: 100%;}
            &-img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                min-width: 50px;
                max-width: 50px;
                max-height: 60px;
                margin-right: 12px;
                & img {
                    max-width: 100%;
                    max-height: 60px;
                }
            }
            &-delete {
                display: block;
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
                & svg {
                    & path {
                        transition: 0.3s ease;
                        fill: $textlightgray2;
                    }
                }
                &:hover {
                    & svg {
                        & path {
                            fill: red;
                        }
                    }
                }
            }
            &-cnt {
                position: relative;
                width: 100%;
                &-top {
                    width: 100%;
                    padding-right: 30px;
                }
                &-title {
                    display: block;
                    margin-bottom: 5px;
                    font-family: 'Century Gothic Regular';
                    font-size: 12px;
                    line-height: 14px;
                    color: $textgray7;
                    transition: 0.3s ease;
                    &:hover {color: $green;}
                }
                &-weight,
                &-added {
                    display: block;
                    font-family: 'Century Gothic Regular';
                    font-size: 11px;
                    line-height: 15px;
                    color: $textgray8;
                    &-el {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                    }
                    & .delete-el {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 8px;
                        margin-left: 5px;
                        transition: 0.3s ease;
                        &:hover {opacity: 0.7;}
                        & img {max-width: 100%;}
                    }
                }
                &-bottom {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 5px;
                    width: 100%;
                }
                &-price {
                    padding-left: 15px;
                    font-family: 'PF Bague Sans Pro Bold';
                    font-size: 14px;
                    line-height: 14px;
                    color: $textgray7;
                }
            }
        }
    }
    &-hidden {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0px 25px 35px;
        @media (max-width: 1200px) {
            padding: 0px 15px 35px;
        }
        &-title {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 28px;
            & img {
                max-width: 31px;
                margin-right: 15px;
            }
            & p {
                font-family: 'Montserrat Regular';
                font-size: 16px;
                line-height: 18px;
                color: $textgray2;
            }
        }
        &-links {
            display: flex;
            align-items: center;
            justify-content: center;
            & a {
                display: block;
                font-family: 'Montserrat Regular';
                font-size: 12px;
                line-height: 12px;
                color: $textgray3;
                transition: 0.3s ease;
                &:hover {color: $green;}
                &:not(:last-child) {margin-right: 10px;}
            }
        }
    }
    &-cnt {
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {width: 2px;}
        &::-webkit-scrollbar-track {background: transparent;}
        &::-webkit-scrollbar-thumb {background: $green;}
        &::-webkit-scrollbar-thumb:hover {background: transparent;}
    }
    &-bottom {
        width: 100%;
        @media (max-width: 1200px) {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
            width: 100%;
            background-color: $white;
            -webkit-overflow-scrolling: touch;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-overflow-scrolling: touch;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
        }
        & .btn-check {display: none;}
        & .btn-check-disabled {display: inline-block;}
        
        @media (max-height: 500px) {
            & .checkout-min {
                padding: 7px 10px;
                font-size: 10px;
                line-height: 10px;
            }
            & .btn-check span {
                min-height: 35px;
            }
        }
    }
    &-min {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 18px 15px;
        font-family: 'Montserrat Regular';
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: $textgray4;
        border-top: 1px solid $bordergray3;
        border-bottom: 1px solid $bordergray3;
    }
    &-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-sum {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            width: 100%;
            max-width: 140px;
            padding: 5px 5px 5px 12px;
            & > span {
                display: inline-block;
                margin-right: 14px;
                font-family: 'Montserrat Regular';
                font-size: 12px;
                line-height: 12px;
                color: $textgray4;
            }
            & p {
                display: inline-block;
                font-family: 'Montserrat Medium';
                font-size: 21px;
                line-height: 21px;
                color: $textgray4;
                & span {
                    font-size: 12px;
                    line-height: 12px;
                }
            }
        }
    }
    &.added {
        & .checkout-bottom {
            & .btn-check {display: block;}
            & .btn-check-disabled {display: none;}
        }
        & .checkout-hidden {display: none !important;}
        & .checkout-form {
            display: block !important;
        }
    }
}

.label-row {
    display: block;
    &:not(:last-child) {margin-bottom: 18px;}
    &-wrap {
        &:not(:last-child) {margin-bottom: 35px;}
    }
    &-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        @media (max-width: 767px) {
            display: block;
            margin-bottom: 0;
        }
        & .label-row {
            width: 48% !important;
            margin: 0 !important;
            @media (max-width: 767px) {
                width: 100% !important;
                margin: 0 0 18px !important;
            }
        }
    }
    & input {
        display: block;
        width: 100%;
        min-height: 50px;
        padding: 5px 10px 5px 22px;
        background: $bordergray6 !important;
        border: 1px solid $bordergray6 !important;
        border-radius: 60px !important;
        outline: none !important;
        font-family: 'Montserrat Regular';
        font-size: 15px;
        line-height: 15px;
        color: $textgray7;
        transition: 0.3s ease;
        &.br-10 {
            border-radius: 10px !important;
        }
        &:focus {
            background: transparent !important;
        }
        &::-webkit-input-placeholder {color: $textlightgray;}
        &::-moz-placeholder {color: $textlightgray;}
        &:-ms-input-placeholder {color: $textlightgray;}
        &:-moz-placeholder {color: $textlightgray;}
    }
    &-big {
        & input {
            min-height: 60px;
            border-radius: 10px !important;
        }
    }
    & textarea {
        display: block;
        width: 100%;
        min-height: 170px;
        padding: 15px 22px 5px;
        background: $bordergray6 !important;
        border: 1px solid $bordergray6 !important;
        border-radius: 10px !important;
        outline: none !important;
        font-family: 'Montserrat Regular';
        font-size: 15px;
        line-height: 15px;
        color: $textgray7;
        transition: 0.3s ease;
        &:focus {
            background: transparent !important;
        }
        &::-webkit-input-placeholder {color: $textlightgray;}
        &::-moz-placeholder {color: $textlightgray;}
        &:-ms-input-placeholder {color: $textlightgray;}
        &:-moz-placeholder {color: $textlightgray;}
    }
    &-text {
        display: none;
        margin-top: 8px;
        font-family: 'Montserrat Medium';
        font-size: 12px;
        line-height: 12px;
        color: #AC0000;
    }
    &.error {
        & p {display: block;}
        & input  {
            border: 1px solid #AC0000 !important;
        }
    }
}

.card-info-price-select {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17px;
        min-width: 17px;
        height: 17px;
        border: 1px solid $bordergray;
        border-radius: 50%;
        transition: 0.3s ease;
        & svg {
            & path,
            & line,
            & g {
                transition: 0.3s ease;
                stroke: $bordergray !important;
            }
        }
        &:hover {
            border: 1px solid $green;
            & svg {
                & path,
                & line,
                & g {
                    stroke: $green !important;
                }
            }
        }
    }
    & input {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 30px;
        border: none;
        background: none;
        outline: none;
        box-shadow: none;
        font-family: 'Montserrat SemiBold';
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: $textgray7;
    }
}

.select-row {
    margin-bottom: 15px;
    &:last-child {margin-bottom: 35px;}
    & > p {
        margin-bottom: 15px;
        font-family: 'Montserrat Light';
        font-size: 14px;
        line-height: 14px;
        color: $textgray9; 
    }
    .custom-select {
        position: relative;
        & select {display: none;}
        & .select-selected {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: transparent;
            &:after {
                position: absolute;
                content: "";
                top: 50%;
                right: 25px;
                transform: translateY(-50%);
                transition: 0.3s ease;
                width: 10px;
                height: 6px;
                background-image: url('../images/arr.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                transition: 0.3s ease;
            }
            &.select-arrow-active {
                &:after {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }
        & .select-items div,
        & .select-selected {
            padding: 5px 40px 5px 25px;
            border: 1px solid $bordergray4;
            border-radius: 10px;
            min-height: 40px;
            cursor: pointer;
            font-family: 'Montserrat Regular';
            font-size: 15px;
            line-height: 15px;
            color: $textgray7; 
        }
        .select-items {
            position: absolute;
            background: $white;
            top: calc(100% - 1px);
            left: 0;
            right: 0;
            z-index: 4;
            border: 1px solid $bordergray4;
            border-radius: 10px;
            overflow: hidden;
            & div {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: none !important;
                border-radius: 0;
            }
        }
        
        .select-hide {
            display: none;
        }
          
        .select-items div:hover, .same-as-selected {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}

.radio {
    &-row {
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        &-center {align-items: center;}
        &:not(:last-child) {margin-bottom: 15px;}
        &-wrap {
            &:not(:last-child) {margin-bottom: 25px;}
        }
        &-select {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 30px;
            max-width: 30px;
            width: 30px;
            height: 30px;
            margin-right: 10px;
            border: 1px solid $bordergray4;
            border-radius: 50%;
            transition: 0.3s ease;
            &::before {
                content: '';
                position: absolute;
                top: calc(50% - 8px);
                left: calc(50% - 8px);
                display: block;
                min-width: 16px;
                max-width: 16px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: $green;
                opacity: 0;
                transition: 0.3s ease;
            }
            &:hover {border: 1px solid $green;}
        }
        &-text {
            margin-top: 5px;
            font-family: 'Montserrat Regular';
            font-size: 15px;
            line-height: 20px;
        }
        &-input {
            display: none;
            &:checked ~ .radio-row-select {
                border: 1px solid $green;
            }
            &:checked ~ .radio-row-select::before {
                opacity: 1;
            }
        }
        &.active {
            & .radio-row-select {
                border: 1px solid $green;
                &::before {
                    opacity: 1;
                }
            }
        }
    }
}
