.contacts {
    padding-bottom: 150px;
    @media (max-width: 992px) {
        padding-bottom: 75px;
    }
    & .wrapper-main {
        @media (max-width: 992px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
        }
    }
    &-map {
        display: block;
        width: 100%;
        height: 395px;
        margin-bottom: 45px;
        @media (max-width: 992px) {
            margin-top: 25px;
            margin-bottom: 0;
            height: 300px;
            order: 3;
            width: 100%;
        }
    }
    & .title {
        @media (max-width: 992px) {
            order: 1;
            width: 100%;
        }
    }
    &-list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 1102px;
        @media (max-width: 992px) {
            order: 2;
            width: 100%;
        }
        @media (max-width: 767px) {
            flex-flow: row wrap;
            justify-content: space-around;
        }
        @media (max-width: 620px) {
            justify-content: space-between;
        }
        @media (max-width: 430px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
        & > div {
            margin-bottom: 25px;
        }
        & .info-link-loc {
            & .info-link-cnt-title {
                font-size: 21px;
                line-height: 29px;
                & span {
                    font-size: 13px;
                    line-height: 15px;
                }
            }
            & .info-link-img {
                max-width: 23px;
                width: 23px;
                margin-right: 13px;
                & img {
                    width: 100%;
                    margin-top: 2px;
                    min-width: 21px;
                    max-width: 21px;
                }
            }
        }
        & .info-link-tel {
            align-items: flex-start;
            & span,
            & span span {
                font-family: 'Montserrat Regular';
                font-size: 16px;
                line-height: 16px;
                color: $textgray7 !important;
            }
            & .info-link-img {
                margin-right: 13px;
                & img {
                    min-width: 21px;
                    max-width: 21px;
                }
            }
            & .info-link-cnt {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                & a {
                    transition: 0.3s ease;
                    color: $textgray7 !important;
                    &:not(:last-child) {margin-bottom: 8px;}
                    &:hover {
                        color: $green !important;
                        & span {color: $green !important;}
                    }
                }
            }
        }
        & .info-link-time {
            align-items: flex-start;
            @media (max-width: 1400px) {
                max-width: 200px;
                br {display: none;}
            }
            & .info-link-cnt-title {
                font-family: 'Montserrat Regular';
                font-size: 15px;
                line-height: 21px;
                color: $textgray7;
            }
            & .info-link-img {
                margin-right: 13px;
                min-width: 21px;
                & img {
                    min-width: 21px;
                    max-width: 21px;
                }
            }
        }
    }
}