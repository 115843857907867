.error-page {
    &-list {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 140px 0 140px 140px;
        @media (max-height: 1200px) {
            padding: 70px 0;
        }
        &-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            margin-right: 110px;
            @media (max-height: 767px) {
                margin-right: 0;
                width: 100%;
            }
            & p {
                width: 100%;
                font-family: 'Montserrat ExtraBold';
                font-size: 31px;
                line-height: 37px;
                text-align: center;
                color: $textgray7;
            }
            & img {
                max-width: 270px;
                margin-top: -30px;
                margin-bottom: -30px;
            }
            & > span {
                display: block;
                width: 100%;
                margin-top: 8px;
                margin-bottom: 20px;
                font-family: 'Montserrat Regular';
                font-size: 15px;
                line-height: 24px;
                text-align: center;
                color: $textgray7;
            }
        }
        &-right {
            width: 100%;
            max-width: 339px;
            @media (max-height: 767px) {
                display: none;
            }
            & img {
                width: 100%;
            }
        }
    }
}