.header {
    width: 100%;
    padding: 26px 0;
    background-color: $white;
    border-bottom: 1px solid $bordergray;
    transition: 0.3s ease;
    @media (max-width: 1200px) {
        padding: 18px 0 0;
    }
    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 7;
        width: 100%;
        @media (max-width: 1200px) {
            box-shadow: 0 0 10px $bordergray;
        }
    }
    &.open {
        & .menu-trigger {
            justify-content: center;
            & span {
                position: relative;
                width: 100%;
                &:first-child {
                    transform: rotate(-40deg);
                    margin-bottom: -2px;
                }
                &:nth-child(2) {display: none;}
                &:last-child {
                    transform: rotate(40deg);
                }
            }
        }
        & .header-left {display: block;}
    }
    & .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 1600px) {
            padding: 0 15px;
        }
        @media (max-width: 1200px) {
            display: block;
        }
    }
    &-links {
        display: none;
        &-wrap {
            display: none;
            @media (max-width: 1200px)  {
                // position: relative;
                display: block;
            }
        }
        &-arrow {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            z-index: 2;
            display: none;
            align-items: center;
            justify-content: flex-end;
            padding: 0 15px 4px;
            @media (max-width: 570px) {
                display: flex;
            }
        }
        @media (max-width: 1200px) {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding: 15px 0;
            background-color: $white;
        }
        @media (max-width: 767px) {
            min-width: 635px;
            justify-content: flex-start;
            &-wrap {
                display: block;
                width: calc(100% + 40px);
                margin-left: -20px;
                overflow: scroll;
                & .header-links {
                    padding-left: 20px;
                }
            }
        }
        & .sidebar-left-cnt-img {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            & svg {
                max-width: 100%;
                max-height: 25px;
                & path,
                & circle {
                    transition: 0.3s ease;
                    fill: $textgray2;
                }
            }
        }
        & a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            & span {
                display: block;
                width: 100%;
                font-family: 'Century Gothic Regular';
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: $textgray2;
                transition: 0.3s ease;
            }
            &:not(:last-child) {
                margin-right: 35px;
                @media (max-width: 767px)  {
                    margin-right: 20px;
                }
            }
            &:hover {
                & .sidebar-left-cnt-img svg path,
                & .sidebar-left-cnt-img svg circle{fill: $green;}
                & span {color: $green;}
            }
        }
    }
    &-left-layout,
    &-right {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    &-left {
        &-top {display: none;}
        & .info-link-tel,
        & .info-link-mob,
        & .search-form-wrap {display: none;}
        @media (max-width: 1200px) {
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 3;
            width: 100%;
            height: calc(100% - 146px);
            padding: 29px 15px 80px;
            overflow: scroll;
            display: none;
            background-color: $textgray7;
            &-layout {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
            }
            & .search-form {
                display: flex;
                max-width: 203px !important;
                &-wrap {
                    order: 3;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: 100%;
                    max-width: 320px;
                }
            }
            & .logo {display: none !important;}
            &-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                max-width: 320px;
                margin-bottom: 30px;
                order: 1;
                & .login-trigger {
                    & img {display: block !important;}
                    color: $white !important;
                }
            }
            & .lang-links a {color: $white;}
            & .info-link {
                display: none !important;
                width: 100%;
                max-width: 320px;
                color: $white;
                order: 5;
                margin-right: 0 !important;
                &-img {
                    max-width: 16px;
                    margin-right: 15px !important;
                }
                & div,
                & span,
                & span span {color: $white !important;}
                &-tel {
                    display: flex !important;
                    order: 5;
                    margin-bottom: 0 !important;
                }
                &-mob {
                    display: flex !important;
                }
                &:not(:last-child) {margin-bottom: 35px;}
            }
            & .search-form {
                max-width: 203px;
                margin-bottom: 35px;
            }
        }
    }
    &-right {
        width: 100%;
        max-width: 660px;
        justify-content: flex-end;
        @media (max-width: 1600px) {
            max-width: 530px;
        }
        & .cart-trigger {
            position: relative;
            display: none;
            margin-left: 20px;
            @media (max-width: 1200px) {
                display: block;
            }
            & span {
                display: none;
                position: absolute;
                top: -4px;
                right: -6px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                font-family: 'Montserrat SemiBold';
                font-size: 9px;
                line-height: 9px;
                color: $white;
                background-color: $green;
            }
            &.active span {display: flex;}
        }
        & .logo,
        & .menu-trigger-wrap {
            display: none !important;
            @media (max-width: 1200px) {display: block !important;}
        }
        & .menu-trigger-wrap {
            display: none;
            @media (max-width: 1200px) {display: block;}
        }
        @media (max-width: 1200px) {
            position: relative;
            justify-content: space-between;
            max-width: 100%;
            padding-bottom: 15px;
            &::before {
                content: '';
                position: absolute;
                top: 100%;
                left: -15px;
                z-index: 1;
                display: block;
                width: calc(100% + 30px);
                height: 1px;
                background-color: $bordergray;
            }
            & .search-form,
            & .info-link,
            & .lang-links,
            & .login-trigger span {display: none;}
        }
    }
    & .logo {
        display: block;
        width: 100%;
        max-width: 221px;
        margin-right: 41px;
        @media (max-width: 1600px) {
            max-width: 150px;
            margin-right: 15px;
        }
        & img {max-width: 100%;}
    }
    & .info-link {
        margin-right: 41px;
        @media (max-width: 1600px) {
            margin-right: 15px;
        }
        &-tel {margin-right: 18px;}
    }
    & nav {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media (max-width: 1200px) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 32px;
            order: 2;
            width: 100%;
            max-width: 320px;
        }
        & a {
            display: block;
            font-family: 'Montserrat Regular';
            font-size: 14px;
            line-height: 14px;
            color: $textlightgray;
            transition: 0.3s ease;
            @media (max-width: 1600px) {
                font-size: 12px;
                line-height: 12px;
            }
            @media (max-width: 1200px) {
                font-family: 'Montserrat Bold';
                font-size: 18px;
                line-height: 24px;
                color: $white;
            }
            &:hover {color: $green;}
            &:not(:last-child) {
                margin-right: 22px;
                @media (max-width: 1600px) {
                    margin-right: 10px;
                }
                @media (max-width: 1200px) {
                    margin: 0  0 17px;
                }
            }
        }
    }
    & .search-form {
        margin-right: 20px;
        @media (max-width: 1600px) {
            margin-right: 10px;
            max-width: 130px;
        }
    }
    & .lang-links {
        margin-right: 38px;
        @media (max-width: 1600px) {
            margin-right: 15px;
        }
    }
    & .info-link-tel {
        & .info-link-cnt-title {
            @media (max-width: 1600px) {
                font-size: 13px;
                line-height: 13px;
                & span {
                    font-size: 13px;
                    line-height: 13px;
                }
            }
        }
    }

}


.menu-trigger {
    display: none;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    width:22px;
    height: 19px;
    transition: 0.6s ease;
    &-wrap {
        width: 100%;
        max-width: 75px;
        @media (max-width: 400px) {
            width: auto;
        }
    }
    @media (max-width: 1200px) {
        display: flex;
    }
}

.menu-trigger span {
	display: block;
	width: 100%;
	height: 2px;
	background-color: $textgray7;
	transition: 0.2s cubic-bezier(0, 0, 0.2, 1);
}