html, body {
    height: 100%;
    &.hidden {
        overflow: hidden;
    }
}

body.hidden {
    @media (min-width: 1201px) {
        margin-right: 17px;
    }
}

.main {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    &-content {flex: 1 0 auto;}
    & footer {flex: 0 0 auto;}
}


.wrapper {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 360px 0 146px;
    @media (max-width: 1200px) {
        padding: 0 15px;
    }
    &-main {
        width: 100%;
        max-width: 1444px;
        margin: 0 auto;
        padding: 0 30px 0 0;
        @media (max-width: 1200px) {
            padding: 0;
        }
    }
    &-xl {
        max-width: 1920px;
        padding: 0 26px;
    }
}

.section-block {
    padding: 70px 0 45px;
    @media (max-width: 1100px) {
        padding: 40px 0;
    }
}


.breadcrumbs {
    padding-top: 25px;
    margin-bottom: 10px;
    & .wrapper-main {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-flow: row wrap;
        width: 100%;
    }
    & a,
    & span {
        display: inline-block;
        font-family: 'Montserrat Regular';
        font-size: 14px;
        line-height: 17px;
        color: $textlightgray2;
        transition: 0.3s ease;
        &.slash {
            margin: 0 7px;
        }
    }
    & a:hover {color: $green;}
}


.lang-links {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #76BBB9;
    border-radius: 20px;
    overflow: hidden;
    & span,
    & a  {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 28px;
        font-family: 'Montserrat Regular';
        font-size: 10px;
        line-height: 10px;
        color: $textgray;
        transition: 0.3s ease;
        &.active {
            background-color: $green;
            color: $white;
        }
    }
    & a:hover {color: $green;}
}

.search-form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 204px;
    min-height: 34px;
    padding: 0 14px 0 15px;
    border: 1px solid $bordergray2;
    border-radius: 20px;
    transition: 0.3s ease;
    &.focus {border: 1px solid $green;}
    & input {
        display: block;
        width: 100%;
        max-width: 160px;
        font-family: 'Montserrat Regular';
        font-size: 12px;
        line-height: 34px;
        max-height: 34px;
        min-height: 34px;
        color: $textlightgray2;
        background: none !important;
        border: none !important;
        border-radius: 0 !important;
        outline: none !important;
        &::-webkit-input-placeholder {color: $textlightgray2;}
        &::-moz-placeholder {color: $textlightgray2;}
        &:-ms-input-placeholder {color: $textlightgray2;}
        &:-moz-placeholder {color: $textlightgray2;}
    }
    & button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        min-width: 18px;
        height: 18px;
        background: none !important;
        border: none !important;
        border-radius: 0 !important;
        outline: none !important;
        & img {
            max-width: 100%;
            margin-top: 1px;
        }
    }
}

.login-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat Regular';
    font-size: 14px;
    line-height: 14px;
    color: $textgray;
    transition: 0.3s ease;
    &-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media (max-width: 1200px) {
            width: 100%;
            max-width: 75px;
        }
    }
    & span {display: block;}
    &:hover {color: $green;}
    & img {
        max-width: 27px;
        margin-right: 8px;
    }
}

.title {
    display: block;
    margin-bottom: 45px;
    font-family: 'Century Gothic Regular';
    font-weight: normal;
    font-size: 52px;
    line-height: 60px;
    color: $textgray;
    @media (max-width: 1200px) {
        margin-bottom: 30px;
        font-size: 32px;
        line-height: 37px;
    }
}

.info-link {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    &-img {
        display: block;
        max-width: 25px;
        min-width: 15px;
        margin-right: 13px;
        & img {
            max-width: 100%;
            margin-top: -2px;
        }
    }
    &-cnt {
        display: block;
        &-title {
            display: block;
            font-family: 'Century Gothic Regular';
            font-size: 16px;
            line-height: 16px;
            color: $textgray;
            transition: 0.3s ease;
            & span {
                display: block;
                font-family: 'Montserrat Regular';
                font-size: 10px;
                line-height: 18px;
                color: $textlightgray;
                transition: 0.3s ease;
            }
        }
    }
    &-tel {
        align-items: center;
        & .info-link-img {
            margin-right: 20px;
            & img {margin-top: 1px;}
        }
        & .info-link-cnt-title {
            display: inline-block;
            font-family: 'Montserrat Regular';
            font-size: 16px;
            line-height: 16px;
            color: $textlightgray3;
            & span {
                display: inline;
                font-family: 'Century Gothic Regular';
                font-size: 16px;
                line-height: 16px;
                color: $textgray;
            }
        }
        &:not(.info-link-tel-white):hover {
            & .info-link-cnt-title,
            & .info-link-cnt-title span {color: $green;}
        }
    }
}

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
    padding: 30px;
    z-index: 1000;
	background:  $white;
	transition: 1s ease;
	opacity: 0;
    visibility: hidden;
    & img {
        display: none;
        width: 100%;
        max-width: 150px;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        & img {
            display: block;
        }
    }
    
}




.section-text {
    padding: 50px 0;
    & i {
        font-family: 'Montserrat Italic';
        font-size: 16px;
        line-height: 24px;
        color: $textgray7;
        &:not(:last-child) {margin-bottom: 24px;}
    }
    & b {
        font-family: 'Montserrat Bold';
        font-size: 16px;
        line-height: 24px;
        color: $textgray7;
        &:not(:last-child) {margin-bottom: 24px;}
    }
    & p {
        font-family: 'Montserrat Regular';
        font-size: 16px;
        line-height: 24px;
        color: $textgray7;
        &:not(:last-child) {margin-bottom: 24px;}
        & a {
            font-family: 'Montserrat Regular';
            font-size: 16px;
            line-height: 24px;
            text-decoration: underline;
            color: $textgray7;
            transition: 0.3s ease;
            &:hover {
                text-decoration: none;
                color: $textgray7;
            }
        }
    }
    & h1 {
        margin-bottom: 25px;
        font-family: 'Century Gothic Regular';
        font-size: 50px;
        line-height: 60px;
        color: $textgray7;
        @media (max-width: 992px) {
            margin-bottom: 15px;
            font-size: 36px;
            line-height: 42px;
        }
    }
    & h2 {
        margin-bottom: 25px;
        font-family: 'Century Gothic Regular';
        font-size: 36px;
        line-height: 42px;
        color: $textgray7;
        @media (max-width: 992px) {
            margin-bottom: 15px;
            font-size: 24px;
            line-height: 30px;
        }
    }
    & h3 {
        margin-bottom: 25px;
        font-family: 'Montserrat Bold';
        font-size: 24px;
        line-height: 30px;
        color: $textgray7;
        @media (max-width: 992px) {
            margin-bottom: 15px;
            font-size: 18px;
            line-height: 22px;
        }
    }
    & h4 {
        margin-bottom: 25px;
        font-family: 'Montserrat SemiBold';
        font-size: 18px;
        line-height: 22px;
        color: $textgray7;
        @media (max-width: 992px) {
            margin-bottom: 15px;
            font-size:16px;
            line-height: 18px;
        }
    }
    & h5 {
        margin-bottom: 25px;
        font-family: 'Montserrat Medium';
        font-size:16px;
        line-height: 18px;
        color: $textgray7;
        @media (max-width: 992px) {
            margin-bottom: 15px;
            font-size:15px;
            line-height: 17px;
        }
    }
    & h6 {
        margin-bottom: 25px;
        font-family: 'Montserrat Regular';
        font-size:15px;
        line-height: 17px;
        color: $textgray7;
        @media (max-width: 992px) {
            margin-bottom: 15px;
            font-size:12px;
            line-height: 15px;
        }
    }
    & ul {
        padding: 0;
        margin: 0 0 24px;
        list-style: none;
        & li {
            position: relative;
            padding-left: 10px;
            font-family: 'Montserrat Regular';
            font-size: 16px;
            line-height: 24px;
            color: $textgray7;
            transition: 0.3s ease;
            &::before {
                content: '';
                position: absolute;
                top: 11px;
                left: 0;
                display: block;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background: $textgray7;
                transition: 0.3s ease;
            }
            & a {
                font-family: 'Montserrat Regular';
                font-size: 16px;
                line-height: 24px;
                color: $textgray7;
                text-decoration: underline;
                transition: 0.3s ease;
                &:hover {
                    color: $textgray7;
                    text-decoration: none;
                }
            }
            &:not(:last-child) {margin-bottom: 5px;}
        }
    }
    & ol  {
        padding: 0 !important;
        margin: 0 0 24px;
        list-style: none;
        & > li {
            position: relative;
            list-style: none;
            counter-increment: my-awesome-counter;
            font-family: 'Montserrat Regular';
            font-size: 16px;
            line-height: 24px;
            color: $textgray7;
            transition: 0.3s ease;
            &::before {
                content: counter(my-awesome-counter) ".";
                display: inline-block;
                min-width: 15px;
                margin-right: 5px;
                font-family: 'Montserrat Bold';
                color: #76BBB9;
            }
            &:not(:last-child) {margin-bottom: 5px;}
        }
    }
    & blockquote {
        position: relative;
        padding: 40px 30px 73px;
        font-family: 'Montserrat Italic';
        font-size: 16px;
        line-height: 24px;
        color: $textgray7;
        transition: 0.3s ease;
        border: 1px solid #76BBB9;
        @media (max-width: 992px) {
            padding: 40px 15px 73px;
            font-size: 12px;
            line-height: 15px;
        }
        & span {
            position: absolute;
            bottom: 32px;
            right: 30px;
            display: block;
            width: 100%;
            text-align: right;
            font-family: 'Montserrat Regular';
            font-size: 16px;
            line-height: 16px;
            color: #BABABA;
        }
        &::before {
            content: '';
            position: absolute;
            top: -10px;
            left: 31px;
            display: block;
            width: 33px;
            height: 27px;
            background-image: url('../images/quote.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
}