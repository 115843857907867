.card {
    padding-top: 65px;
    padding-bottom: 50px;
    @media (max-width: 600px) {
        padding-top: 20px;
        padding-bottom: 45px;
    }
    &-p {
        margin-bottom: 20px;
        font-family: 'Century Gothic Bold';
        font-size: 16px;
        line-height: 16px;
        color: $textgray7;
    }
    &-head {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 100% !important;
        margin: 0 0 50px!important;
        @media (max-width: 600px) {
            flex-direction: column;
            align-items: center;
        }
        & .breadcrumbs {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-flow: row wrap;
            padding-top: 0;
            margin-bottom: 6px;
        }
        &-hidden {
            display: none;
            @media (max-width: 600px) {
                display: block;
                width: 100%;
            }
            & .title {
                margin-bottom: 30px;
            }
        }
        &-img {
            width: 100%;
            max-width: 490px;
            margin-right: 50px;
            height: auto;
            min-height: 0;
            max-height: 100%;
            margin-bottom: 0;
            @media (max-width: 600px) {
                margin: 0 0 30px;
            }
            & img {max-width: 100%;}
        }
        &-cnt {
            width: 100%;
            & .breadcrumbs {
                @media (max-width: 600px) {
                    display: none;
                }
            }
            & h1 {
                margin-bottom: 20px;
                font-family: 'Century Gothic Regular';
                font-size: 52px;
                line-height: 60px;
                color: $textgray;
                @media (max-width: 1200px) {
                    margin-bottom: 30px;
                    font-size: 32px;
                    line-height: 37px;
                }
                @media (max-width: 600px) {
                    display: none;
                }
            }
            &-in {
                display: block;
                margin-bottom: 27px;
                font-family: 'Montserrat Regular';
                font-size: 15px;
                line-height: 24px;
                color: $textgray7;
            }
            & .checkout-form-item {
                display: none;
            }
            & .catalog-row-item-weight {
                max-width: 251px;
                margin-bottom: 44px;
            }
            & .buy-block {
                display: flex;
                align-items:center;
                justify-content: flex-start;
                & .btn {
                    width: 100%;
                    max-width: 150px;
                }
                & p {
                    margin-left: 28px;
                    width: 100%;
                    font-family: 'Montserrat SemiBold';
                    font-size: 24px;
                    line-height: 24px;
                    color: $textgray7;
                }
            }
        }
    }
    & .cabinet-links {
        margin-bottom: 27px;
    }
    &-added{
        &-list {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-flow: row wrap;
            @media (max-width: 560px) {
                justify-content: space-between;
            }
            @media (max-width: 390px) {
                justify-content: space-between;
            }
            @media (max-width: 350px) {
                justify-content: center;
            }
        }
    }
    &-add {
        position: relative;
        display: block;
        width: 100%;
        max-width: 174px;
        padding: 14px 8px 19px;
        background-color: $white;
        border: 1px solid $textlightgray2;
        border-radius: 20px;
        margin-right: 6px;
        margin-bottom: 7px;
        @media (max-width: 560px) {
            margin: 0 3px 7px;
        }
        @media (max-width: 390px) {
            margin-right: 0;
            max-width: 160px;
        }
        @media (max-width: 350px) {
            max-width: 174px;
        }
        &-title {
            width: 100%;
            font-family: 'Century Gothic Regular';
            font-size: 16px;
            line-height: 25px;
            text-align: center;
            color: $textgray7; 
        }
        &-img {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 2px;
            width: 100%;
            height: 115px;
            max-height: 115px;
            & img {
                max-width: 100%;
                max-height: 115px;
            }
        }
        &-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & p {
                display: inline-block;
                padding: 0 5px;
                min-width: 55px;
                min-height: 28px;
                font-family: 'Montserrat Regular';
                font-size: 12px;
                line-height: 28px;
                text-align: center;
                color: $textgray7; 
                border: 1px solid $bordergray4;
                border-radius: 20px;
                & span {
                    font-size: 9px;
                }
            }
            & > span {
                font-family: 'Montserrat SemiBold';
                font-size: 15px;
                line-height: 15px;
                color: $textgray7; 
                & span {
                    font-size: 12px;
                    line-height: 12px;
                }
            }
            & a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 29px;
                min-width: 29px;
                height: 29px;
                border-radius: 50%;
                border: 1px solid $green;
                transition: 0.3s ease;
                & svg {
                    max-width: 14px;
                    width: 14px;
                    & path {
                        fill: $green;
                        transition: 0.3s ease;
                    }
                }
                &:hover {
                    background-color: $green;
                    & svg {
                        & path {
                            fill: $white;
                        }
                    }
                }
            }
        }
        & .delete-item {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 29px;
            min-width: 29px;
            height: 29px;
            border-radius: 50%;
            border: 1px solid $textlightgray2;
            transition: 0.3s ease;
            opacity: 0;
            visibility: hidden;
            & svg {
                max-width: 13px;
                width: 13px;
                & path {
                    fill: $textlightgray2;
                    transition: 0.3s ease;
                }
            }
            &:hover {
                border: 1px solid red;
                background-color: red;
                & svg {
                    & path {
                        fill: white;
                    }
                }
            }
        }
        &.add {
            border: 1px solid $green;
            & .delete-item {
                visibility: visible;
                opacity: 1;
            }
            & .add-to-card {
                background-color: $green;
                & svg {
                    & path {
                        fill: $white;
                    }
                }
            }
        }
    }
}