.thanks-page {
    &-cnt {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: 70px 0 70px 140px;
        width: 100%;
        max-width: 476px;
        @media (max-height: 1200px) {
            padding: 70px 0;
            justify-content: center;
            max-width: 100%;
        }
        & h1 {
            width: 100%;
            margin-bottom: 15px;
            font-family: 'Montserrat SemiBold';
            font-size: 36px;
            line-height: 40px;
            text-align: center;
            color: $textgray7;
        }
        & img {
            max-width: 254px;
            margin-bottom: 43px;
        }
        & p {
            display: block;
            width: 100%;
            margin-bottom: 25px;
            font-family: 'Montserrat Regular';
            font-size: 15px;
            line-height: 24px;
            text-align: center;
            color: $textgray7;
        }
        & .btn {
            width: 100%;
            max-width: 240px;
            & span {
                min-height: 44px;
                font-family: 'Montserrat SemiBold';
                font-size: 18px;
                line-height: 18px;
                text-transform: initial;
            }
        }
    }
}