.catalog {
    &-row {
        padding-bottom: 50px;
        &-title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 53px;
            @media (max-width: 767px) {
                flex-flow: row wrap;
                margin-bottom: 38px;
            }
            &-img {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
            }
            & p {
                margin-right: 16px;
                font-family: 'Century Gothic Regular';
                font-size: 33px;
                line-height: 37px;
                color: $textgray7;
                @media (max-width: 767px) {
                    width: calc(100% - 70px);
                    margin-right: 0;
                }
            }
            & a {
                display: inline-block;
                padding: 5px 16px;
                border: 1px solid $textgray8;
                border-radius: 30px;
                font-family: 'Montserrat Regular';
                font-size: 14px;
                line-height: 14px;
                color: $textgray8;
                transition: 0.3s ease;
                @media (max-width: 767px) {
                    margin-top: 20px;
                }
                &:hover {
                    color: $green;
                    border: 1px solid $green;
                }
            }
        }
        &-list {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            @media (max-width: 992px) {
                flex-flow: row wrap;
                justify-content:space-around;
            }
        }
        &-item {
            display: block;
            width: 100%;
            max-width: 251px;
            margin-bottom: 35px;
            @media (max-width: 1600px) {
                max-width: 211px;
                &:not(.not) {
                    &:nth-child(5) {display: none;}
                }
            }
            @media (max-width: 1350px) {
                &:not(.not) {
                    &:nth-child(4) {display: none;}
                }
            }
            @media (max-width: 1200px) {
                &:not(.not) {
                    &:nth-child(4),
                    &:nth-child(5) {display: block;}
                }
            }
            @media (max-width: 1070px) {
                &:not(.not) {
                    &:nth-child(5) {display: none;}
                }
            }
            @media (max-width: 992px) {
                &:not(.not) {
                    &:nth-child(5) {display: block;}
                }
            }
            @media (max-width: 992px) {
                margin: 0 20px 35px;
            }
            @media (max-width: 767px) {
                max-width: 251px;
            }
            &:not(:last-child) {
                margin-right: 20px;
                @media (max-width: 1750px) {
                    margin-right: 10px;
                }
            }
            &-new {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 17px 5px;
                border: 1px solid $green;
                border-radius: 20px;
                &-img {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 252px;
                    min-height: 252px;
                    max-height: 252px;
                    margin-bottom: 14px;
                    @media (max-width: 1750px) {
                        height: 226px;
                        min-height: 226px;
                        max-height: 226px;
                    }
                    @media (max-width: 767px) {
                        height: 252px;
                        min-height: 252px;
                        max-height: 252px;
                    
                    }
                    & img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                & .catalog-row-item-title {
                    height:auto;
                    margin-bottom: 0;
                    display: -webkit-box;
                    -webkit-line-clamp:4;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                }
            }
            & .checkout-form-item {display: none !important;}
            &-title {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 52px;
                margin-bottom: 10px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                overflow: hidden;
                -webkit-box-orient: vertical;
                font-family: 'Century Gothic Regular';
                font-size: 24px;
                line-height: 26px;
                text-align: center;
                color: $textgray7;
                transition: 0.3s ease;
                &:hover {color: $green;}
            }
            &-img {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 251px;
                min-height: 144px;
                max-height: 144px;
                margin-bottom: 43px;
                & img {
                    max-width: 100%;
                    max-height: 100%;
                }
                & span:not(.info) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: inline-block;
                    width: 100%;
                    padding: 20px;
                    border-radius: 0 0 25px 25px;
                    background-color: rgba(0, 0, 0, 0.44);
                    transition: 0.3s ease;
                    opacity: 0;
                    font-family: 'Montserrat Light';
                    font-size: 14px;
                    line-height: 21px;
                    color: $white;
                }
                & span.info {
                    position: absolute;
                    top: 15px;
                    left: 0;
                    display: inline-block;
                    width: auto;
                    padding: 8px 20px;
                    border-radius: 0 15px 15px 0;
                    background-color: #EEE4B6;
                    transition: 0.3s ease;
                    opacity: 1;
                    font-family: 'Montserrat Regular';
                    font-size: 10px;
                    line-height: 10px;
                    color: $textgray7;
                }
                &:hover span {opacity: 1;}
            }
            &-i {
                & .catalog-row-item-img {
                    & span:not(.info) {
                        padding: 50px 20px 20px;
                    }
                }
            }
            &-weight {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-bottom: 23px;
                &-item {
                    display: block;
                    width: 100%;
                    & a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        min-height: 38px;
                        margin-bottom: 4px;
                        font-family: 'Montserrat Regular';
                        font-size: 14px;
                        line-height: 14px;
                        color: $textlightgray;
                        border-top: 1px solid $bordergray4;
                        border-bottom: 1px solid $bordergray4;
                        transition: 0.3s ease;
                    }
                    & span {
                        display: block;
                        width: 100%;
                        font-family: 'Montserrat Regular';
                        font-size: 14px;
                        line-height: 14px;
                        text-align: center;
                        color: $textlightgray;
                        transition: 0.3s ease;
                    }
                    &.active {
                        & a {
                            background-color: $bordergray4;
                            color: $textgray7;
                        }
                        & span {
                            color: $textgray7;
                        }
                    }
                    &:first-child:not(:last-child) {
                        & a {
                            border: 1px solid $bordergray4;
                            border-radius: 20px 0 0 20px;
                        }
                    }
                    &:last-child:not(:first-child) {
                        & a {
                            border: 1px solid $bordergray4;
                            border-radius: 0 20px 20px 0;
                        }
                    }
                    &:not(:nth-child(2)) {
                        & a {
                            border-radius: 20px ;
                        }
                    }
                }
            }
            &-bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                & .btn {
                    width: 100%;
                    max-width: 123px;
                    min-width: 123px;
                }
                & p {
                    width: 100%;
                    max-width: 102px;
                    margin-left: 10px;
                    font-family: 'Montserrat SemiBold';
                    font-size: 22px;
                    line-height: 22px;
                    color: $textgray7;
                    & span {
                        &:nth-child(1) {
                            font-size: 22px;
                            line-height: 22px;
                        }
                        &:nth-child(2) {
                            font-size: 16px;
                            line-height: 16px;
                        }
                    }
                    &.el-price {
                        position: relative;
                        & .el-weight {
                            position: absolute;
                            bottom: calc(100% + 5px);
                            left: 0;
                            font-family: 'Montserrat Regular';
                            font-size: 14px;
                            line-height: 14px;
                            color: $textgray7;
                        }
                    }
                }
            }
        }
    }
    &-main {
        padding-bottom: 30px;
        &-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 992px) {
                flex-direction: column;
                align-items: flex-start;
            }
            & .select-row {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-left: 0;
                @media (max-width: 992px) {
                    order: 1;
                    margin-bottom: 25px;
                    flex-direction: column;
                    align-items: flex-start;
                }
                & p {
                    margin: 0 15px 0 0;
                    @media (max-width: 992px) {
                        margin: 0 0 11px;
                    }
                }
            }
            & .cabinet-links {
                @media (max-width: 992px) {
                    order: 2;
                }
            }
        }
        & .catalog-row-list {
            flex-flow: row wrap;
        }
    }
}