.promotions {
    padding-bottom: 205px;
    @media (max-width: 992px) {
        padding-bottom: 75px;
    }
    &-list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-flow: row wrap;
        width: 100%;
        max-width: 1230px;
    }
    & .promotion {
        display: block;
        width: 48%;
        margin-bottom: 39px;
        @media (max-width: 1400px) {
            width: 100%;
            max-width: 630px;
            margin: 0 auto 39px;
        }
        &-img {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 291px;
            min-height: 291px;
            margin-bottom: 15px;
            overflow: hidden;
            border-radius: 10px;
            @media (max-width: 767px) {
                min-height: 0;
                height: auto;
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.4);
                opacity: 0;
                transition: 0.3s ease;
            }
            & img {
                width: 100%;
                max-height: 100%;
                @media (max-width: 1400px) {
                    width: auto;
                }
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }
        &-title {
            display: block;
            margin-bottom: 9px;
            font-family: 'Montserrat SemiBold';
            font-size: 24px;
            line-height: 32px;
            color: $textgray7;
            transition: 0.3s ease;
            @media (max-width: 1400px) {
                text-align: center;
            }
            @media (max-width: 767px) {
                font-size: 18px;
                line-height: 24px;
            }
        }
        &-text {
            display: block;
            font-family: 'Montserrat Medium';
            font-size: 12px;
            line-height: 18px;
            color: $textlightgray;
            @media (max-width: 1400px) {
                text-align: center;
            }
        }
        &:hover {
            & .promotion-img::before {opacity: 1;}
            & .promotion-title {color: $green;}
        }
    }
    &-item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
        &-img {
            width: 100%;
            max-width: 600px;
            margin-bottom: 30px;
            margin-right: 33px;
            border-radius: 10px;
            overflow: hidden;
            @media (max-width: 1200px) {
                max-width: 400px;
            }
            @media (max-width: 767px) {
                max-width: 100%;
                margin-right: 0;
            }
            & img {
                max-width: 100%;
                width: 100%;
            }
        }
        &-cnt {
            width: 100%;
            & p {
                font-family: 'Montserrat Regular';
                font-size: 15px;
                line-height: 24px;
                color: $textgray7;
                & span {
                    color: $textlightgray2;
                }
                &:not(:last-child) {margin-bottom: 24px;}
            }
        }
    }
}