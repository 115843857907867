.popup {
    position: relative;
    width: 100%;
    max-width: 639px;
    background: $white;
    border-radius: 30px;
    &-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding: 15px;
        z-index: 40;
        display: none;
        transition: 0.5s ease;
        &.active {display: block;}
        &.modal {
            & .popup {
                max-width: 340px;
            }
        }
    }
    &-layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 20px;
        overflow-y: scroll;
        background: rgba(36, 37, 42, 0.7);
        @media (max-height: 500px) {
            align-items: flex-start;
        }
    }
    &-close {
        position: absolute;
        top: 21px;
        right: 21px;
        min-width: 15px;
        width: 15px;
        max-width: 15px;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover {opacity: 0.7;}
        img {width: 100%;}
    }
    &-head {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 25px 40px;
        min-height: 86px;
        border-bottom: 1px solid $bordergray;
        font-family: 'Montserrat SemiBold';
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: $textgray7;
        border-bottom: 2px solid $bordergray4;
        @media (max-width: 767px) {
            padding: 10px 35px;
        }
    }
    &-cnt {
        &-wrap {
            padding: 30px;
            @media (max-width: 767px) {
                padding: 30px 10px;
            }
        }
        &-form {
            width: 100%;
            & form {
                & .label-row {
                    width: 100%;
                    &:not(:last-child) {margin-bottom: 20px;}
                }
            }
            & .btn {
                display: block;
                width: 100%;
                max-width: 280px;
                &:not(:last-child) {margin-bottom: 8px;}
                @media (max-width: 767px) {
                    margin-left: auto;
                    margin-right: auto
                }
            }
            &-column {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                @media (max-width: 767px) {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                & p {
                    padding-top: 20px;
                    font-family: 'Montserrat Regular';
                    font-size: 15px;
                    line-height: 15px;
                    color: $textgray7;
                    @media (max-width: 767px) {
                        padding-top: 0;
                    }
                    & a {
                        font-family: 'Montserrat Regular';
                        font-size: 15px;
                        line-height: 15px;
                        color: $green;
                        text-decoration: underline;
                        &:hover {
                            color: $green;
                            text-decoration: none;
                        }
                    }
                }
                &-row {
                    width: 100%;
                    max-width: 280px;
                    margin-left: 15px;
                    @media (max-width: 767px) {
                        max-width: 100%;
                        margin: 20px 0 0;
                    }
                }
            }
            &-caller {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 10px;
                & a {
                    font-family: 'Montserrat Regular';
                    font-size: 15px;
                    line-height: 15px;
                    color: $green;
                    text-decoration: underline;
                    &:hover {
                        color: $green;
                        text-decoration: none;
                    }
                }
            }
            &-text {
                margin-bottom: 21px;
                font-family: 'Montserrat Regular';
                font-size: 15px;
                line-height: 24px;
                text-align: center;
                color: $textgray7;
            }
        }
    }
    &-thanks {
        & .popup-cnt-wrap {
            padding: 0 30px 0 0 !important;
            @media (max-width: 767px) {
                padding: 0 15px 57px !important;
            }
        }
        & .popup-cnt {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            @media (max-width: 767px) {
                flex-direction: column;
                align-items: center;
            }
            & .popup-img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 247px;
                min-width: 130px;
                padding: 10px;
                min-height: 214px;
                margin-right: 66px;
                border-right: 2px solid $bordergray;
                @media (max-width: 767px) {
                    max-width: 100%;
                    min-width: 0;
                    min-height: 144px;
                    margin-right: 0;
                    margin-bottom: 25px;
                    border-right: 0px solid $bordergray;
                    border-bottom: 2px solid $bordergray;
                }
                & img {
                    max-width: 100%;
                    max-height: 100%;
                    @media (max-width: 767px) {max-width: 79px;}
                }
            }
            & h5 {
                font-family: 'Gilroy Medium';
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                color: #989BB2;
                @media (max-width: 767px) {
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                }
            }
        }
    }
}


